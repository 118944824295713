<template>
  <v-content id="browse-container" style="height: 100%;">
    <portal to="navbar-left" style="flex: 1">
      <v-layout mt-2 style="flex: 1">
        <v-text-field
          id="toolbar-textfield"
          placeholder="search history"
          @keyup.enter="getWebPages(true)"
          full-width
          single-line
          autofocus
          v-model="url"
        ></v-text-field>
      </v-layout>
    </portal>

    <v-layout
      v-if="loading"
      style="height: 100%; align-items: center; justify-content: center"
    >
      <h2 v-if="!error">loading history...</h2>
      <h2 v-if="error">{{ error }}</h2>
    </v-layout>

    <v-layout
      v-if="!error && !loading && pages && pages.length === 0"
      style="height: 100%; align-items: center; justify-content: center"
    >
      <h2>No results</h2>
    </v-layout>

    <v-list id="browse-list" v-if="pages" subheader>
      <v-list-tile
        v-for="page in pages"
        :key="page.txid || page.hash"
        :href="`/rebuild/${page.hash}`"
      >
        <v-list-tile-content>
          <v-list-tile-title v-html="page.url"></v-list-tile-title>
        </v-list-tile-content>
        <v-btn
          flat
          class="text-none"
          style="min-width: 40px; margin: 0"
          :href="`${BLOCK_EXPLORER_URL}${page.txid}`"
          target="_blank"
        >
          {{ page.time_ago }}
        </v-btn>
      </v-list-tile>
    </v-list>
    <div style="height: 36px" />
  </v-content>
</template>

<script>
import config from '../config'
import helpers from '../helpers'
const { API_ROOT } = config
const { getUrlVars, formatDate } = helpers

export default {
  name: 'browse',
  data () {
    return {
      BLOCK_EXPLORER_URL: '',
      pages: null,
      page: 0,
      finished: false,
      error: null,
      url: '',
      loading: false
    }
  },
  methods: {
    getWebPages (start) {
      if (start) {
        this.finished = false
        this.pages = null
        this.error = null
        this.page = 0
      } else if (this.finished || this.loading) {
        return
      }
      this.loading = true
      const { search } = getUrlVars()
      if (search && !this.url) {
        window.history.pushState(null, null, window.location.pathname)
      } else if (decodeURIComponent(search || '') !== this.url) {
        window.history.pushState(
          null,
          null,
          `${window.location.pathname}?search=${this.url}`
        )
      }
      const LIMIT = 500
      const url = `?limit=${LIMIT}&offset=${LIMIT *
        this.page}&history=1&search=${this.url}`

      this.$http
        .get(`${API_ROOT}/pages${url}`)
        .then(resp => {
          const pages = resp.body.pages
          this.BLOCK_EXPLORER_URL = resp.body.BLOCK_EXPLORER_URL
          if (pages.length === 0) this.finished = true
          this.page += 1
          if (!this.pages) this.pages = []
          pages.map(page => {
            if (page.time) {
              page.time_ago = formatDate(page.time * 1000)
            } else {
              page.time_ago = 'a few minutes ago'
            }
            this.pages.push(page)
          })
        })
        .catch(err => {
          this.error = err.body.error
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  created () {
    const { search } = getUrlVars()
    if (search) {
      this.url = decodeURIComponent(search)
    }
    this.getWebPages(true)
  },
  mounted () {
    window.onscroll = () => {
      const container = document.getElementById('browse-container')
      const list = document.getElementById('browse-list')
      if (
        list &&
        window.scrollY + container.offsetHeight + 20000 >= list.offsetHeight
      ) {
        this.getWebPages()
      }
    }
  }
}
</script>

<style></style>
