import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Donate from './views/Donate.vue'
import Browse from './views/Browse.vue'
import History from './views/History.vue'
import Preview from './views/Preview.vue'
import Redirect from './views/Redirect.vue'
import NotFound from './views/NotFound.vue'
import Rebuild from './views/Rebuild.vue'
import Stats from './views/Stats.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/about',
      name: 'about',
      component: Donate,
    },
    {
      path: '/donate',
      name: 'about',
      component: Donate,
    },
    {
      path: '/browse',
      name: 'browse',
      component: Browse,
    },
    {
      path: '/history',
      name: 'history',
      component: History,
    },
    {
      path: '/rebuild/:hash',
      name: 'rebuild',
      component: Rebuild,
    },
    {
      path: '/c/:id',
      name: 'redirect',
      component: Redirect,
    },
    {
      path: '/stats',
      name: 'stats',
      component: Stats,
    },
    {
      path: '/:id',
      name: 'preview',
      component: Preview,
    },
    {
      path: '/:id/*',
      name: 'preview',
      component: Preview,
    },
    {
      path: '*',
      name: 'notfound',
      component: NotFound,
    },
  ],
})
