import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/src/stylus/app.styl'

Vue.use(Vuetify, {
  iconfont: 'md',
  theme: {
    // primary: '#1A237E',
    // secondary: '#1A237E',
    // accent: '#1A237E',
    // light: '#1A237E',
    // error: '#b71c1c',
    // light: '#1976d2',
  }
})
