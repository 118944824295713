<template>
  <v-content style="height: 100%">
    <portal to="navbar-left" style="flex: 1">
      <v-layout mt-2 v-if="data" style="flex: 1">
        <v-text-field
          id="toolbar-textfield"
          placeholder="enter a web page"
          :disabled="loading"
          @keyup.enter="submit"
          full-width
          single-line
          v-model="url"
        ></v-text-field>
      </v-layout>
    </portal>

    <v-layout fill-height>
      <v-layout v-if="!data && !loading" fill-height align-center>
        <v-layout column align-center mb-5 pb-5>
          <v-layout mb-2>
            <h1 v-if="!error" class="landing color-blue">etched</h1>
            <h2 v-if="error" class="color-blue">{{ error }}</h2>
          </v-layout>
          <v-layout mb-4 class="landing-text-field">
            <v-text-field
              autofocus
              placeholder="enter a web page"
              outline
              single-line
              :disabled="loading"
              @keyup.enter="submit"
              prepend-inner-icon="search"
              v-model="url"
            >
              <template v-slot:append>
                <v-btn
                  v-if="
                    !!url &&
                      url.indexOf('.') > 0 &&
                      url.indexOf('.') < url.length - 1
                  "
                  @click="submit"
                  depressed
                  class="ma-0"
                  color="transparent"
                  style="min-width: 40px; height: 52px; margin: 0"
                >
                  <h3 style="color: #1976d2;">GO</h3>
                </v-btn>
              </template>
            </v-text-field>
          </v-layout>
          <span v-if="!error" class="color-blue"
            >archive a web page forever on bitcoin</span
          >
          <v-btn
            flat
            style="margin-top: 16px"
            href="https://chrome.etched.page"
            target="_blank"
          >
            <svg
              width="20px"
              height="20px"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 496 512"
              style="margin-right: 4px"
            >
              <path
                fill="lightgray"
                d="M131.5 217.5L55.1 100.1c47.6-59.2 119-91.8 192-92.1 42.3-.3 85.5 10.5 124.8 33.2 43.4 25.2 76.4 61.4 97.4 103L264 133.4c-58.1-3.4-113.4 29.3-132.5 84.1zm32.9 38.5c0 46.2 37.4 83.6 83.6 83.6s83.6-37.4 83.6-83.6-37.4-83.6-83.6-83.6-83.6 37.3-83.6 83.6zm314.9-89.2L339.6 174c37.9 44.3 38.5 108.2 6.6 157.2L234.1 503.6c46.5 2.5 94.4-7.7 137.8-32.9 107.4-62 150.9-192 107.4-303.9zM133.7 303.6L40.4 120.1C14.9 159.1 0 205.9 0 256c0 124 90.8 226.7 209.5 244.9l63.7-124.8c-57.6 10.8-113.2-20.8-139.5-72.5z"
              ></path>
            </svg>
            <h5 style="font-weight: 400; font-size: 10px; color: gray">
              install chrome extension
            </h5>
          </v-btn>
        </v-layout>
      </v-layout>

      <v-layout v-if="loading" fill-height align-center>
        <v-layout column justify-center align-center mb-5 pb-5>
          <h2 v-if="loading" class="color-blue">loading site...</h2>
          <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
        </v-layout>
      </v-layout>

      <v-layout v-if="data" style="height: 100%" column>
        <v-alert
          v-if="data && data.size > data.MAX_FILE_SIZE"
          :value="true"
          type="warning"
        >
          The site is greater than the allowed
          {{ data.MAX_FILE_SIZE_PRETTY }} limit
        </v-alert>
        <v-alert v-if="success" :value="true" type="success">
          The site was archived! <br />
          <a
            :href="data.finalUrl"
            target="_blank"
            id="saved-link"
            style="word-break: break-all;"
            >{{ data.finalUrl }}</a
          ><v-btn flat icon small color="white" style="margin: 0px 0px 0px 10px"
            ><v-icon @click="$copyText(data.finalUrl)"
              >flip_to_front</v-icon
            ></v-btn
          >
          <span v-if="userId"
            ><br /><v-btn
              flat
              small
              color="white"
              style="margin: 0px 0px 0px 10px"
              class="text-none"
              @click="$copyText(`${data.referralUrl}${userId}`)"
              >earn 33% from sharing your referral link:&nbsp;&nbsp;&nbsp;{{
                `${data.referralUrl}${userId}`
              }}</v-btn
            ></span
          >
        </v-alert>
        <v-layout class="containerz">
          <iframe
            v-if="!!previewSrc"
            id="preview-iframe"
            ref="previewIframe"
            :src="previewSrc"
            frameBorder="0"
            width="100%"
            height="100%"
            sandbox="allow-forms allow-popups allow-pointer-lock allow-same-origin allow-scripts"
          />
          <iframe
            v-if="!!previewSrcPdf"
            id="preview-iframe"
            ref="previewIframe"
            :src="previewSrcPdf"
            frameBorder="0"
            width="100%"
            height="100%"
          />
          <v-list class="right-side" wrap>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <em style="padding-left: 10px" v-on="on"
                  >{{ totalSize }} total</em
                >
              </template>
              <span>{{ data.type }} ({{ data.prettySize }})</span>
            </v-tooltip>
            <v-divider></v-divider>
            <v-layout
              v-if="
                !success &&
                  !errorFree &&
                  data &&
                  data.isFree &&
                  !data.success &&
                  !data.exists
              "
              justify-center
            >
              <v-btn
                round
                color="info"
                :disabled="loadingFree"
                class="text-none"
                style="color: white"
                @click="saveForFree"
              >
                <v-icon>cloud_download</v-icon>&nbsp;
                {{
                  loadingFree
                    ? `archiving web page...`
                    : `click to archive web page`
                }}
              </v-btn>
            </v-layout>
            <v-dialog v-model="errorFree" max-width="340px">
              <v-card>
                <v-card-title>
                  <span
                    >Sorry, we could not archive the web page for free :(</span
                  >
                </v-card-title>
                <v-card-actions>
                  <v-btn color="primary" flat @click="errorFree = null"
                    >Close</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-layout v-if="data && !data.isFree && !data.TESTNET" column>
              <ListItemFile
                v-if="!data.exists"
                style="margin-left: 10px"
                :file="data"
                :disabled="true"
                :ASSET_URL="data.ASSET_URL"
                @payment="handlePayment"
                @error="paymentError"
              />
              <v-list-tile
                v-for="file in freeFiles"
                :key="file.hash"
                style="margin-top: 12px"
              >
                <ListItemFile
                  :file="file"
                  :ASSET_URL="data.ASSET_URL"
                  @payment="handlePayment"
                  @change="onFileChanged"
                  @error="paymentError"
                />
              </v-list-tile>
            </v-layout>
            <v-expansion-panel v-if="data && data.isFree">
              <v-expansion-panel-content>
                <template v-slot:header style="padding: 12px 12px">
                  <span> ({{ freeFiles.length + 1 }}) web text files </span>
                </template>
                <v-card>
                  <v-card-text>
                    <ul>
                      <li>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <span v-on="on"
                              >{{ data.globalType }} ({{
                                data.prettySize
                              }})</span
                            >
                          </template>
                          <span
                            >{{ data.originalFilename }} ({{
                              data.prettySize
                            }})</span
                          >
                        </v-tooltip>
                      </li>
                      <li
                        style="margin-top: 14px"
                        :key="file.hash"
                        v-for="file in freeFiles"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <span v-on="on" style="word-break: break-all"
                              >{{ file.originalFilename }} ({{
                                file.prettySize
                              }})</span
                            >
                          </template>
                          <span>{{ file.url }} ({{ file.prettySize }})</span>
                        </v-tooltip>
                      </li>
                    </ul>
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-divider style="margin-top: 10px"></v-divider>
            <span v-if="data && !data.TESTNET" style="padding-left: 10px"
              >{{ data.fee }}¢ plus miner fee per file</span
            >
            <v-layout
              row
              style="margin-left: 10px; padding: 0,0,0,0; height: 40px"
              align-center
              v-if="otherFiles && otherFiles.length > 0"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-checkbox
                    style="flex: 0"
                    v-on="on"
                    color="info"
                    input-value="true"
                    @change="toggleImages()"
                  ></v-checkbox>
                </template>
                <span
                  >{{ enableAllImages ? 'Disable' : 'Enable' }} all images</span
                >
              </v-tooltip>
              <span>Images:</span>
            </v-layout>
            <v-list-tile
              v-for="file in otherFiles"
              :key="file.hash"
              style="margin-top: 12px;"
            >
              <ListItemFile
                :file="file"
                :ASSET_URL="data.ASSET_URL"
                @payment="handlePayment"
                @change="onFileChanged"
                @error="paymentError"
              />
            </v-list-tile>
            <v-expansion-panel v-if="jsFiles && jsFiles.length > 0">
              <v-expansion-panel-content>
                <template v-slot:header style="padding: 12px 12px">
                  <span> ({{ jsFiles.length }}) javascript files </span>
                </template>
                <v-list
                  style="overflow-y: scroll; width: 295px; height: 100%; text-align: left"
                  wrap
                >
                  <v-list-tile
                    v-for="file in jsFiles"
                    :key="file.hash"
                    style="margin-top: 12px; overflow: hidden"
                  >
                    <ListItemFile
                      :file="file"
                      :ASSET_URL="data.ASSET_URL"
                      @payment="handlePayment"
                      @change="onFileChanged"
                      @error="paymentError"
                    />
                  </v-list-tile>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel v-if="fontFiles && fontFiles.length > 0">
              <v-expansion-panel-content>
                <template v-slot:header style="padding: 12px 12px">
                  <span> ({{ fontFiles.length }}) font files </span>
                </template>
                <v-list
                  style="overflow-y: scroll; width: 295px; height: 100%; text-align: left"
                  wrap
                >
                  <v-list-tile
                    v-for="file in fontFiles"
                    :key="file.hash"
                    style="margin-top: 12px; overflow: hidden"
                  >
                    <ListItemFile
                      :file="file"
                      :ASSET_URL="data.ASSET_URL"
                      @payment="handlePayment"
                      @change="onFileChanged"
                      @error="paymentError"
                    />
                  </v-list-tile>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel v-if="unknownFiles && unknownFiles.length > 0">
              <v-expansion-panel-content>
                <template v-slot:header style="padding: 12px 12px">
                  <span> ({{ unknownFiles.length }}) unknown files </span>
                </template>
                <v-list
                  style="overflow-y: scroll; width: 295px; height: 100%; text-align: left"
                  wrap
                >
                  <v-list-tile
                    v-for="file in unknownFiles"
                    :key="file.hash"
                    style="margin-top: 12px; overflow: hidden"
                  >
                    <ListItemFile
                      :file="file"
                      :ASSET_URL="data.ASSET_URL"
                      @payment="handlePayment"
                      @change="onFileChanged"
                      @error="paymentError"
                    />
                  </v-list-tile>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel
              v-if="assetsThatExist && assetsThatExist.length > 0"
            >
              <v-expansion-panel-content>
                <template v-slot:header style="padding: 12px 12px">
                  <span>
                    ({{ assetsThatExist.length }}) files are already on bitcoin
                  </span>
                </template>
                <v-card>
                  <v-card-text>
                    <ul>
                      <li :key="file.hash" v-for="file in assetsThatExist">
                        <a
                          :href="`${data.ASSET_URL}${file.txid || file.hash}`"
                          target="_blank"
                          >{{ file.originalFilename }}</a
                        >
                      </li>
                    </ul>
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-list>
        </v-layout>
      </v-layout>
    </v-layout>
  </v-content>
</template>

<script>
import ListItemFile from '../components/ListItemFile'
import config from '../config'
import helpers from '../helpers'

const { API_ROOT, CLIENT_IDENTIFIER } = config
const { prettyBytes, getUrlVars, getReferral, removeReferral } = helpers

export default {
  name: 'home',
  components: {
    ListItemFile
  },
  data () {
    return {
      data: null,
      url: '',
      loading: false,
      loadingFree: false,
      error: null,
      errorFree: null,
      success: false,
      userId: null,
      enableAllImages: true,
      CLIENT_IDENTIFIER
    }
  },
  computed: {
    previewSrc () {
      const { data } = this
      if (!data) return null
      const { previewUrl, contentType, updated } = data
      if (!contentType.includes('pdf'))
        return `${previewUrl}?t=${updated || +new Date()}`
      return ''
    },
    previewSrcPdf () {
      const { data } = this
      if (!data) return null
      const { previewUrl, contentType, updated } = data
      if (contentType.includes('pdf'))
        return `${previewUrl}?t=${updated || +new Date()}`
      return ''
    },
    assetsThatExist () {
      const { data } = this
      return data && data.files.filter(f => !!f.exists)
    },
    fontFiles () {
      const { data } = this
      if (!data || data.TESTNET) return false
      return data.files.filter(f => !f.exists && f.globalType === 'font')
    },
    jsFiles () {
      const { data } = this
      if (!data || data.TESTNET) return false
      return data.files.filter(f => !f.exists && f.globalType === 'js')
    },
    freeFiles () {
      const { data } = this
      if (data && data.TESTNET) {
        return data.files.filter(f => !f.exists)
      }
      return data && data.files.filter(f => !f.exists && f.globalType === 'css')
    },
    unknownFiles () {
      const { data } = this
      if (!data || data.TESTNET) return false
      return data.files.filter(f => !f.exists && f.globalType === 'unknown')
    },
    otherFiles () {
      const { data } = this
      if (!data || data.TESTNET) return false
      return data.files.filter(f => !f.exists && f.globalType === 'image')
    },
    totalSize () {
      const { data } = this
      if (!data) return 0
      let total = data.size
      data.files.map(f => {
        if (f.included && !f.exists && f.validSize) {
          total += f.size
        }
      })
      return prettyBytes(total)
    }
  },
  methods: {
    setIncludedFilesCookies (data) {
      const includedFiles = data.files
        .filter(f => !!f.included || !!f.exists || !!f.success)
        .map(f => f.hash.slice(0, 8))
      includedFiles.push(data.hash.slice(0, 8))
      this.$cookie.set('scrapeSettings', includedFiles.join(','))
      try {
        this.$cookie.set('origin', new URL(data.url).origin)
      } catch (err) {
        // Ignore
      }
    },
    onFileChanged () {
      this.setIncludedFilesCookies(this.data)
      this.data.updated = +new Date()
      this.data = { ...this.data }
    },
    toggleImages () {
      this.enableAllImages = !this.enableAllImages
      for (const file of this.data.files) {
        if (file.globalType === 'image') {
          file.included = this.enableAllImages
          if (file.success || file.exists) {
            file.included = true
          }
        }
      }
      this.onFileChanged()
    },
    submit () {
      if (!this.url) return
      this.loading = true
      this.data = null
      this.error = null
      this.success = false
      const referral = getReferral()
      this.$cookie.delete('scrapeSettings')

      const { url, promo, nohash } = getUrlVars()
      if (url && !this.url) {
        window.history.pushState(null, null, window.location.pathname)
      } else if (decodeURIComponent(url || '') !== this.url) {
        window.history.pushState(
          null,
          null,
          `${window.location.pathname}?url=${this.url}`
        )
      }
      if (!this.url) return

      this.$http
        .post(`${API_ROOT}/site`, { url: this.url, promo, referral, nohash })
        .then(resp => {
          this.setIncludedFilesCookies(resp.data)
          this.data = resp.data
          this.url = this.data.url
          if (this.data.exists) {
            this.success = true
          }
        })
        .catch(err => {
          this.error = (err.body && err.body.error) || 'Could not fetch site :('
        })
        .finally(() => {
          this.loading = false
        })
    },
    saveForFree () {
      this.loadingFree = true
      this.errorFree = null
      const { promo } = getUrlVars()
      const body = { ...this.data, promo }
      body.files = body.files.filter(file => !!file.signature)
      this.$http
        .post(`${API_ROOT}/free`, body)
        .then(resp => {
          const { success, finalUrl } = resp.body
          if (success) {
            this.success = true
            this.data.finalUrl = finalUrl || this.data.finalUrl
            this.data.exists = true
            this.data.files.map(file => {
              if (file.signature) {
                file.exists = true
              }
            })
            this.onFileChanged()
          }
        })
        .catch(() => {
          if (!this.data.TESTNET) {
            this.data.isFree = false
          }
          this.errorFree = true
        })
        .finally(() => {
          this.loadingFree = false
        })
    },
    handlePayment (payment) {
      // eslint-disable-next-line
      console.log('handle payment', payment)
      if (this.data.ENABLE_REFERRALS && payment && payment.userId) {
        this.userId = payment.userId
      }
      if (payment && payment.txid && payment.buttonId) {
        if (payment.buttonId === this.data.hash) {
          this.success = true
        } else {
          for (const file of this.data.files) {
            if (file.hash === payment.buttonId) {
              file.success = true
            }
          }
        }
      }
    },
    paymentError (err) {
      const referral = getReferral()
      // eslint-disable-next-line
      console.log('payment error', err)
      if (referral && err.message === 'unexpected error') {
        removeReferral()
        try {
          this.data.files.map(file => {
            if (file.tx) {
              file.tx = file.tx.filter(tx => tx.to !== referral)
            }
          })
          this.data.tx = this.data.tx.filter(tx => tx.to !== referral)
        } catch (err) {
          // Ignore
        }
      }
    }
  },
  created () {
    const { c, url } = getUrlVars()
    if (c) {
      this.$router.push({ name: 'preview', params: { id: c } })
    } else if (url) {
      this.url = decodeURIComponent(url)
      this.submit()
      // window.history.pushState(null, null, window.location.pathname)
    }
  }
}
</script>
<style>
h1.landing {
  font-size: 3.4em;
}

.v-list__tile {
  padding: 0 16px 0 10px;
}

.v-text-field.v-text-field--enclosed .v-input__append-inner {
  margin: 0px;
}

.landing-text-field {
  min-width: 360px;
  height: 56px;
}

.color-blue {
  color: #1976d2;
}

.v-alert {
  margin: 0 auto;
  width: 100%;
}

.text-red {
  color: red;
  text-align: left;
  font-size: 12px;
}

#saved-link {
  color: white;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  text-align: left;
  font-size: 0.9em;
  font-family: courier;
}

em {
  font-weight: bold;
  font-style: normal;
  font-family: 'Comfortaa', Sans-Serif;
}

span {
  font-family: 'Comfortaa', Sans-Serif;
  word-break: break-word;
  text-align: left;
}

#toolbar-textfield {
  color: #999;
}

#preview-iframe {
  flex: 1;
  border-width: 1px;
  border-color: lightgray;
}

.containerz {
  overflow: scroll;
  flex: 1;
  flex-direction: row;
}

.right-side {
  overflow-y: scroll;
  width: 295px;
  text-align: left;
}

@media only screen and (max-width: 650px) {
  .containerz {
    flex-direction: column;
  }

  .right-side {
    width: 100%;
    max-height: 40%;
  }
}
</style>
