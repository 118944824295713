<template>
  <div class="chart-containerz">
    <LineChart v-if="loaded" :chartdata="chartdata1" :options="options" />
    <!-- <LineChart v-if="loaded" :chartdata="chartdata2" :options="options" /> -->
  </div>
</template>

<script>
import LineChart from '../components/Chart.vue'
import config from '../config'
const { API_ROOT } = config

export default {
  name: 'stats',
  components: { LineChart },
  data: () => ({
    loaded: false,
    chartdata1: null,
    chartdata2: null,
    options: {}
  }),
  async mounted () {
    this.loaded = false
    try {
      const result = await fetch(`${API_ROOT}/stats`)
      const { charts } = await result.json()

      this.chartdata1 = charts[0]
      // this.chartdata2 = charts[1]

      this.loaded = true
    } catch (e) {
      // eslint-disable-next-line
      console.error(e)
    }
  }
}
</script>
<style>
.chart-containerz {
  padding: 60px;
  width: '100%';
  height: '100%';
}
</style>
