<template>
  <v-layout align-center>
    <v-tooltip bottom v-if="!success">
      <template v-slot:activator="{ on }">
        <v-checkbox
          v-if="file.validSize && !disabled"
          style="flex: 0"
          v-on="on"
          color="info"
          v-model="file.included"
          @change="onFileChanged(file)"
        ></v-checkbox>
        <v-checkbox
          v-if="!file.validSize || disabled"
          style="flex: 0"
          color="info"
          v-on="on"
          :input-value="disabled ? true : !!file.validSize"
          :disabled="true"
        ></v-checkbox>
      </template>
      <span>{{ file.url }} ({{ file.prettySize }})</span>
    </v-tooltip>
    <a
      v-if="success"
      style="text-decoration: none; margin-right: 6px"
      :href="`${ASSET_URL}${file.hash}`"
      target="_blank"
      ><v-icon>open_in_new</v-icon></a
    >
    <v-img
      v-if="file.thumbnail"
      max-width="50"
      width="50"
      max-hight="50"
      height="50"
      :src="file.thumbnail"
    />
    <v-tooltip v-if="!file.validSize" bottom>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="text-red"
          >{{ file.originalFilename }} is too big ({{ file.prettySize }})</span
        >
      </template>
      <span>{{ file.url }} ({{ file.prettySize }})</span>
    </v-tooltip>
    <v-layout
      v-if="file.included && file.validSize"
      style="width: 200px; overflow: hidden"
    >
      <MoneyButton
        :label="`to save ${file.globalType}`"
        :outputs="file.tx"
        :buttonId="file.hash"
        :clientIdentifier="CLIENT_IDENTIFIER"
        @payment="onPayment"
        @error="onError"
      />
    </v-layout>
    <v-tooltip v-if="!file.included && file.validSize" bottom>
      <template v-slot:activator="{ on }">
        <span v-on="on" style="font-size: 12px;"
          >{{ file.originalFilename }} ({{ file.prettySize }})</span
        >
      </template>
      <span>{{ file.url }} ({{ file.prettySize }})</span>
    </v-tooltip>
  </v-layout>
</template>

<script>
import config from '../config'
import MoneyButton from 'vue-money-button'
const { CLIENT_IDENTIFIER } = config

export default {
  data () {
    return {
      success: false,
      CLIENT_IDENTIFIER
    }
  },
  props: {
    file: { type: Object, default: undefined },
    disabled: { type: Boolean, default: false },
    ASSET_URL: { type: String, default: '' }
  },
  methods: {
    onPayment (...args) {
      if (args[0] && args[0].txid) {
        this.success = true
      }
      this.$emit('payment', ...args)
    },
    onError (...args) {
      this.$emit('error', ...args)
    },
    onFileChanged (...args) {
      this.$emit('change', ...args)
    }
  },
  components: {
    MoneyButton
  }
}
</script>
<style></style>
