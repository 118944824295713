import Vue from 'vue'
import './plugins/vuetify'
import App from './App.vue'
import VueResource from 'vue-resource'
import VueCookie from 'vue-cookie'
import PortalVue from 'portal-vue'
import VueClipboard from 'vue-clipboard2'

import router from './router'
import store from './store'

/* eslint-disable no-console */

Vue.use(VueResource)
Vue.use(VueCookie)
Vue.use(PortalVue)
Vue.use(VueClipboard)

Vue.config.productionTip = false
Vue.prototype.$store = store

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')
