<template>
  <v-content style="overflow: scroll; height: 100%;">
    <v-layout align-center justify-center>
      <v-layout
        style="max-width: 440px; margin: 20px;"
        column
        justify-center
        align-center
      >
        <h1>What is etched?</h1>
        <p>
          etched is an internet archive tool that permanently timestamps and
          stores web pages directly into the Bitcoin BSV blockchain.
        </p>
        <p>
          This is a major improvement from traditional web archivers as all
          etched pages are permanently stored and independently provable by
          anyone who has access to the bitcoin blockchain. This means even if
          etched shuts down anyone can search and view all previously saved data
          using bitcoin browsers like
          <a href="https://bottle.bitdb.network" target="_blank">Bottle</a>.
        </p>
        <!-- <h2>Disclaimer</h2>
        <p>
          That being said, any abusive or illegal material will be reported and
          will not be hosted on this site at our discretion. Please be mindful
          of how you use this service as everything you save is permantly tied
          to your bitcoin payments. You can contact us at abuse@etched.page
        </p> -->
        <h3>Donate to the "click to archive web page" wallet:</h3>
        <p>
          To those people with BSV: Help support the archiving of the internet
          by donating to the pool of funds used for transaction miner fees. Each
          1 BSV will archive between 1,000 - 100,000 web files. This makes it
          possible for non-bitcoin users to use this service to help archive the
          internet without requiring a wallet with bitcoin.
        </p>
        <h1 v-if="donated">Thank you for your donation!</h1>
        <qrcode-vue :value="donationAddress" />
        <v-layout ml-5 pl-4>
          <MoneyButton
            label="Donate"
            editable
            buttonId="donation"
            :to="donationAddress"
            :clientIdentifier="CLIENT_IDENTIFIER"
            currency="USD"
            @payment="donated = true"
          />
        </v-layout>
        <span
          >{{ donationAddress
          }}<v-btn flat icon small style="margin: 0px 0px 0px 10px"
            ><v-icon @click="$copyText(donationAddress)"
              >flip_to_front</v-icon
            ></v-btn
          ></span
        >

        <a href="https://twitter.com/etchedpage" target="_blank"
          ><v-img
            width="100"
            height="100"
            src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaWQ9IkxheWVyXzEiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTI7IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsOiM0MzlDRDY7fQo8L3N0eWxlPjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0xOTYuOSw0NjJjMTIwLjMsMCwxODYuMS05OS43LDE4Ni4xLTE4Ni4xYzAtMi44LDAtNS42LTAuMi04LjVjMTIuOC05LjMsMjMuOC0yMC43LDMyLjYtMzMuOSAgYy0xMS45LDUuMy0yNC42LDguOC0zNy42LDEwLjNjMTMuNy04LjIsMjMuOS0yMSwyOC44LTM2LjJjLTEyLjgsNy42LTI2LjksMTMtNDEuNSwxNS45Yy0yNC44LTI2LjMtNjYuMi0yNy42LTkyLjUtMi44ICBjLTE3LDE2LTI0LjIsMzkuOC0xOC45LDYyLjVjLTUyLjYtMi42LTEwMS42LTI3LjUtMTM0LjgtNjguM2MtMTcuNCwyOS45LTguNSw2OC4xLDIwLjIsODcuM2MtMTAuNC0wLjMtMjAuNi0zLjEtMjkuNy04LjIgIGMwLDAuMywwLDAuNSwwLDAuOGMwLDMxLjEsMjIsNTcuOSw1Mi41LDY0LjFjLTkuNiwyLjYtMTkuNywzLTI5LjUsMS4xYzguNiwyNi42LDMzLjEsNDQuOSw2MS4xLDQ1LjQgIGMtMjMuMiwxOC4yLTUxLjgsMjguMS04MS4yLDI4LjFjLTUuMiwwLTEwLjQtMC4zLTE1LjYtMC45QzEyNi41LDQ1MS44LDE2MS4zLDQ2MiwxOTYuOSw0NjIiIGlkPSJYTUxJRF8yMl8iLz48L3N2Zz4="
        /></a>
        <div style="height: 50px" />
      </v-layout>
    </v-layout>
  </v-content>
</template>

<script>
import MoneyButton from 'vue-money-button'
import QrcodeVue from 'qrcode.vue'
import config from '../config'
// :to="3170"
const { CLIENT_IDENTIFIER } = config
export default {
  name: 'about',
  data () {
    return {
      CLIENT_IDENTIFIER,
      donated: false,
      donationAddress: '12nq8ZcrpY6tVkuPzuW9sg4a76feLMHUaN'
    }
  },
  components: {
    MoneyButton,
    QrcodeVue
  }
}
</script>

<style></style>
