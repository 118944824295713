const APP_ROOT = process.env.VUE_APP_ROOT || 'http://192.168.86.28:4000/'
// const APP_ROOT = process.env.VUE_APP_ROOT || 'http://localhost:4000/'
// const APP_ROOT = process.env.VUE_APP_ROOT || 'https://etched.page/'
const API_ROOT = `${APP_ROOT}api`
const CLIENT_IDENTIFIER = 'b9fc2d8e531ae4e6e49ee41e6ebd81b4'

export default {
  API_ROOT,
  CLIENT_IDENTIFIER,
}
