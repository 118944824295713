<template>
  <v-content style="height: 100%">
    <portal to="navbar-left">
      <v-layout row v-if="page">
        <a
          :href="page.name"
          style="word-break: break-all"
          class="hidden-xs-only"
          id="toolbar-textfield"
          >{{ page.name }}</a
        >
        <a
          :href="page.name"
          class="hidden-sm-and-up"
          style="font-size: 10px; word-break: break-all"
          id="toolbar-textfield"
          >{{ page.name }}</a
        >
      </v-layout>
    </portal>

    <portal to="navbar-right">
      <v-toolbar-items v-if="page" class="hidden-xs-only">
        <v-tooltip bottom :disabled="!page.date">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              flat
              id="toolbar-textfield"
              class="text-none"
              style="font-size: 12px"
              :href="`${BLOCK_EXPLORER_URL}${page.txid}`"
              target="_blank"
            >
              {{ page.time_ago }}
            </v-btn>
          </template>
          <span>{{ page.date }}</span>
        </v-tooltip>
      </v-toolbar-items>
    </portal>

    <v-layout fill-height v-if="cid">
      <iframe
        id="view-iframe"
        v-if="!!viewSrc"
        ref="viewIframe"
        :src="viewSrc"
        frameBorder="0"
        sandbox="allow-forms allow-popups allow-pointer-lock allow-same-origin allow-scripts"
      />
      <iframe
        v-if="!!viewSrcPdf"
        id="view-iframe"
        ref="viewIframe"
        :src="viewSrcPdf"
        frameBorder="0"
        width="100%"
        height="100%"
      />
    </v-layout>
  </v-content>
</template>

<script>
import config from '../config'
import moment from 'moment'
import helpers from '../helpers'

const { API_ROOT } = config
const { formatDate } = helpers

export default {
  name: 'preview',
  data () {
    return {
      BLOCK_EXPLORER_URL: '',
      cid: null,
      page: null,
      isPdf: false
    }
  },
  computed: {
    viewSrc () {
      if (this.cid && !this.isPdf) {
        return `${API_ROOT}/c/${this.cid}`
      }
      return null
    },
    viewSrcPdf () {
      if (this.cid && this.isPdf) {
        return `${API_ROOT}/c/${this.cid}`
      }
      return null
    }
  },
  methods: {
    getFileName (cid) {
      this.cid_name = null
      this.$http
        .get(`${API_ROOT}/name/${cid}`)
        .then(resp => {
          this.page = resp.body
          this.BLOCK_EXPLORER_URL = this.page.BLOCK_EXPLORER_URL
          if (this.page.time) {
            this.page.time_ago = formatDate(this.page.time * 1000)
            this.page.date = moment(this.page.time * 1000).toString()
          } else {
            this.page.time_ago = 'a few minutes ago'
          }
          if (this.page.type && this.page.type.includes('application/pdf')) {
            this.isPdf = true
          } else {
            this.isPdf = false
          }
        })
        .catch(() => {
          // console.log('ERROR', err)
          // TODO
        })
    }
  },
  async created () {
    let { id, url } = this.$route.params
    if (url) {
      url = `https://${url}`
      this.$http.get(`${API_ROOT}/pages?search=${url}`).then(resp => {
        for (const page of resp.body.pages) {
          if (page.name === url) {
            this.cid = page.hash
            this.getFileName(this.cid)
            return
          }
        }
        this.cid = 'notfound'
        this.getFileName(this.cid)
      })
    } else {
      this.cid = id
      this.getFileName(this.cid)
    }
  }
}
</script>
<style>
#toolbar-textfield {
  color: #999;
  text-decoration: none;
}

#view-iframe {
  width: 100%;
  height: 100%;
  position: relative;
  float: left;
  /* pointer-events: none; */
}
</style>
