<template>
  <v-content>
    <v-layout column style="height: 100%; justify-content: center">
      <h1>404</h1>
      <p>
        Could not find web page :(
      </p>
    </v-layout>
  </v-content>
</template>

<script>
export default {
  name: 'notfound'
}
</script>
<style></style>
