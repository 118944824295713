import moment from 'moment'

function prettyBytes(num) {
  if (typeof num !== 'number' || isNaN(num)) {
    throw new TypeError('expected a number')
  }

  let exponent,
    unit,
    units = ['b', 'kb', 'mb']

  exponent = Math.min(
    Math.floor(Math.log(num) / Math.log(1000)),
    units.length - 1
  )
  num = (num / Math.pow(1000, exponent)).toFixed(0) * 1
  unit = units[exponent]
  return `${num} ${unit}`
}

function getUrlVars() {
  const vars = {}
  window.location.href.replace(
    /[?&]+([^=&]+)=([^&]*)/gi,
    function (m, key, value) {
      vars[key] = value
    }
  )
  return vars
}

function getReferral() {
  let referral = ''
  try {
    referral = localStorage.getItem('referral')
  } catch (err) {
    // Ignore
  }
  return referral
}
function setReferral(value) {
  try {
    localStorage.setItem('referral', value)
    // eslint-disable-next-line
    console.log('Saved referral code', value)
  } catch (err) {
    // Ignore
  }
}
function removeReferral() {
  try {
    const referal = getReferral()
    localStorage.removeItem('referral')
    // eslint-disable-next-line
    console.log('Removed referral code', referal)
  } catch (err) {
    // Ignore
  }
}

function formatDate(date) {
  return date > +new Date() - 60 * 60 * 21 * 1000
    ? moment(date).fromNow()
    : moment(date).format('ll')
}

export default {
  prettyBytes,
  getUrlVars,
  getReferral,
  setReferral,
  removeReferral,
  formatDate,
}
