<template>
  <v-app id="app">
    <v-toolbar app dark>
      <v-toolbar-items>
        <v-btn flat class="text-none" href="/">
          <v-toolbar-title>etched</v-toolbar-title>
        </v-btn>
      </v-toolbar-items>

      <portal-target name="navbar-left"></portal-target>
      <portal-target name="navbar-right" style="flex: 0"></portal-target>

      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn
          flat
          style="font-size: 12px"
          href="/browse"
          @click="$router.push({ name: 'browse' })"
        >
          browse
        </v-btn>
      </v-toolbar-items>
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn
          flat
          style="font-size: 12px"
          href="/history"
          @click="$router.push({ name: 'history' })"
        >
          history
        </v-btn>
      </v-toolbar-items>
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn
          flat
          style="font-size: 12px"
          href="/about"
          @click="$router.push({ name: 'about' })"
        >
          about
        </v-btn>
      </v-toolbar-items>

      <v-menu dark>
        <template v-slot:activator="{ on }">
          <v-toolbar-side-icon
            v-on="on"
            class="hidden-md-and-up"
          ></v-toolbar-side-icon>
        </template>

        <v-list>
          <v-list-tile href="/browse" @click="$router.push({ name: 'browse' })">
            <v-list-tile-title>browse</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            href="/history"
            @click="$router.push({ name: 'history' })"
          >
            <v-list-tile-title>history</v-list-tile-title>
          </v-list-tile>
          <v-list-tile href="/about" @click="$router.push({ name: 'about' })">
            <v-list-tile-title>about</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
    </v-toolbar>

    <router-view></router-view>

    <v-footer dark app v-if="window.width >= 650">
      <v-spacer></v-spacer>
      <span style="color: gray">etched &copy; 2020</span>
      <v-spacer></v-spacer>
    </v-footer>
  </v-app>
</template>

<script>
import helpers from './helpers'
const { getUrlVars, setReferral } = helpers

export default {
  name: 'app',
  data () {
    return {
      window: {
        width: 0,
        height: 0
      }
    }
  },
  created () {
    const { r } = getUrlVars()
    if (r) {
      setReferral(r)
      // window.history.pushState(null, null, window.location.pathname)
    }

    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize () {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    }
  }
}
</script>

<style>
#app {
  font-family: 'Comfortaa', Sans-Serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
  height: 100vh;
}

.vue-portal-target {
  text-align: left;
  flex: 1;
}
</style>
